<template>
  <section class="notFound">
    <div class="container">
      <div class="text">
        <h1>You are lost</h1>
        <h1><router-link to="/">GO HOME</router-link></h1>
      </div>
      <figure><img src="imgs/404.jpg" alt="" /></figure>
    </div>
  </section>
</template>
